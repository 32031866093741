@import url("https://fonts.googleapis.com/css?family=Roboto");

body {
    margin: 0;
    padding: 0;
    font-family: "Roboto", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #404041;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

a {
    text-decoration: none !important;
}

h1 {
    font-weight: normal;
    font-size: 2em;
    color: #f3f3f3;
    padding-top: 0.5em;
}

h2 {
    font-weight: normal;
    font-size: 1.5em;
    color: #f3f3f3;
    padding-top: 0.5em;
}

h3 {
    font-weight: lighter;
    font-size: 1.2em;
    color: #f3f3f3;
    padding-top: 0.5em;
}

.dark {
    color: #404041;
}

.light {
    color: #f3f3f3;
}

.App {
    padding-bottom: 15%;
}

/* Picker fix (https://github.com/Adphorus/react-date-range/issues/288) */
.rdrCalendarWrapper, .rdrDateRangeWrapper {
    width: 100%;
}

.rdrMonth {
    width: 100% !important;
}

.userContainer {
    width: 100%;
}

@media (min-width: 1800px) {
    .userContainer {
        max-width: 78vw;
    }
}
