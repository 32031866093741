.Header {

  text-align: center;

  img {
    width: 60px;
    height: 60px;
    margin: 1em 0;
  }

}
